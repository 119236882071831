import React from 'react';
// import { navigate } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/take-part/hero';
import WhereToTakePart from '../components/take-part/where-to-take-part';
import Eligibility from '../components/take-part/eligibility';
import HowItWorks from '../components/take-part/how-it-works';
import WhyTakePart from '../components/take-part/why-take-part';

const TakePart = () => (
  <Layout>
    <SEO
      title='Take Part'
      description=''
    />
    <Hero />
    <div className='container mx-auto lg:flex'>
      <WhereToTakePart />
      <Eligibility />
    </div>
    <HowItWorks />
    <WhyTakePart />
  </Layout>
);

export default TakePart;
