import React from 'react';
import Clinic from './clinics/clinic';

const WhereToTakePart = () => (
  <div className='mb-16 pt-16 px-8 xl:w-4/6 lg:w-3/6'>
    <div className='mr-12'>
      <h2 className='font-sans font-semibold text-blue text-4xl leading-very-tight mb-5'>
        Where can I take part?
      </h2>

      <div className='font-sans font-normal text-grey leading-snug mb-6'>
        The following doctors are participating in the NL Genome Project and accepting their current patients.
      </div>

      <div className='font-sans font-normal text-grey leading-snug mb-6'>
        Eligible patients can consent to join the NL Genome Project remotely from home using a phone, video conferencing, and/or the Participant Portal.
      </div>
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. David Brentnall'
        clinic='Family Medical Clinic/Walk In Clinic'
        street='Suite 201, 1489 Topsail Road'
        city='Paradise, NL'
        postal='A1L 1P9'
        phone='709.781.8715'
      />
      <Clinic
        doctor="Dr. Dennis O'Keefe"
        clinic='Commonwealth Medical Clinic'
        street='77 Commonwealth Ave'
        city='Mount Pearl, NL'
        postal='A1N 1W7'
        phone='709.364.7555'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Emily Godec'
        clinic='Saltwater Family Wellness'
        street='40 Airport Heights Drive'
        city="St. John's, NL"
        postal='A1A 4Z7'
        phone='709.552.1122'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Jacqueline Elliott'
        clinic='Port Blandford Medical Clinic'
        street='244 Main Street'
        city='Port Blandford, NL'
        postal='A0C 2G0'
        phone='709.543.2490'
      />

      <Clinic
        doctor='Dr. Tony Gabriel'
        clinic='Gander Medical Clinic'
        street='177 Elizabeth Dr'
        city='Gander, NL'
        postal='A1V 1H6'
        phone='709.256.7101'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Lynette Power'
        clinic='Burin Medical Clinic'
        street='43 Main Street'
        city='Burin Bay Arm, NL'
        postal='A0E 1G0'
        phone='709.891.5005'
      />

      <Clinic
        doctor='Dr. Chris Peddle'
        clinic='TC Medical Clinic'
        street='16 Goff Ave'
        city='Carbonear, NL'
        postal='A1Y 1A6'
        phone='709.596.5136'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. John Janes'
        clinic='Commonwealth Medical Clinic'
        street='77 Commonwealth Av'
        city='Mount Pearl, NL'
        postal='A1N 1W7'
        phone='709.364.7555'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Jamison Mercer'
        clinic='Paradise Wellness Clinic'
        street='1386 Topsail Road'
        city='Paradise, NL'
        postal='A1L 1P6'
        phone='709.782.4123'
      />

      <Clinic
        doctor='Dr. Wendy Graham'
        clinic='Dr. Wendy Graham PMC Limited'
        street='37 Main Street'
        city='Channel-Port aux Basques, NL'
        postal='A0M 1C0'
        phone='709.695.7775'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Lesley Manning'
        clinic='Paradise Wellness Clinic'
        street='1386 Topsail Road'
        city='Paradise, NL'
        postal='A1L 1P6'
        phone='709.782.4123'
      />

      <Clinic
        doctor='Dr. Megan Dawe'
        clinic='Paradise Wellness Clinic'
        street='1386 Topsail Road'
        city='Paradise, NL'
        postal='A1L 1P6'
        phone='709.782.4123'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Chris Dicks'
        clinic='Gander Medical Clinic'
        street='177 Elizabeth Drive'
        city='Gander, NL'
        postal='A1V 1H6'
        phone='709.256.7101'
      />

      <Clinic
        doctor='Dr. Emmanuel Jones'
        clinic="Clarke's Beach Medical Clinic"
        street="16 Shaw's Lane"
        city="Clarke's Beach, NL"
        postal='A0A 1W0'
        phone='709.786.6611'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Nancy Barker'
        clinic='Pasadena Medical Clinic'
        street='14 Main Street'
        city='Corner Brook, NL'
        postal='A2H 1B8'
        phone='709.388.0820'
      />

      <Clinic
        doctor='Dr. Lorena Power'
        clinic='Pasadena Medical Clinic'
        street='14 Main Street'
        city='Corner Brook, NL'
        postal='A2H 1B8'
        phone='709.388.0870'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Ahmad Ibrahim'
        clinic='Gander Medical Clinic'
        street='177 Elizabeth Drive'
        city='Gander, NL'
        postal='A1V 1H6'
        phone='709.256.7101'
      />
    </div>
    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Andrew Hutton'
        clinic='Dr. Hutton Family Practice'
        street='250 Lemarchant Road'
        city='St. John’s, NL'
        postal='A1E 1P7'
        phone='709.739.7444'
      />

      <Clinic
        doctor='Dr. Paula Pye'
        clinic='Family Medical Practice'
        street='246 Memorial Drive'
        city='Clarenville, NL'
        postal='A5A 1N9'
        phone='709.466.5400'
      />
    </div>
    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Blaine Pearce'
        clinic='Family Medical Practice'
        street='246 Memorial Drive'
        city='Clarenville, NL'
        postal='A5A 1N9'
        phone='709.466.5400'
      />

      <Clinic
        doctor='Dr. Gert Nel'
        clinic='Dr. A. A. Wilkinson Memorial Health Centre'
        street='Station Drive'
        city='Old Perlican, NL'
        postal='A0A 3G0'
        phone='709.587.2200'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Stephen Lee'
        clinic='Riverdale Medical Clinic'
        street='842 Conception Bay Hwy'
        city='Conception Bay South, NL'
        postal='A1X 7T4'
        phone='709.240.3020'
      />

      <Clinic
        doctor='Dr. Jason Efford'
        clinic='Avalon Medical'
        street='408-412 Conception Bay Highway'
        city='Bay Roberts'
        postal='A0A 1X0'
        phone='709.786.0091'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. William Moulton'
        clinic='Medical Clinic, Marystown'
        street='16 - 24 Columbia Drive'
        city='Marystown, NL'
        postal='A0E 2M0'
        phone='709.279.2631'
      />

      <Clinic
        doctor='Dr. Todd Young'
        clinic='Main Street Medical Clinic'
        street='165 Main Street, P.O. Box 10'
        city='Springdale, NL'
        postal='A0J 1T0'
        phone='1.877.578.4861'
      />
    </div>

    <div className='xl:flex'>
      <Clinic
        doctor='Dr. Terry Maher'
        clinic='Deer Lake Medical Clinic'
        street='4 Clinic Drive'
        city='Deer Lake, NL'
        postal='A8A 1E3'
        phone='709.635.5150'
      />
    </div>
  </div>
);

export default WhereToTakePart;
