import React from 'react';

const WhereToTakePart = () => (
  <div className='container mx-auto py-16 px-8'>
    <h2 className='font-sans font-semibold text-blue text-4xl leading-very-tight mb-4'>
      Why take part?
    </h2>
    <div className='flex flex-col lg:flex-row'>
      <div className='lg:w-1/2 mr-12'>
        <p className='font-sans font-normal text-grey leading-snug mb-4'>
          People take part in health research for many different reasons, whether to help advance science or to learn more about their own health.
        </p>
      </div>

      <div className='lg:w-1/2'>
        <p className='font-sans font-normal text-grey leading-snug'>
          It's important to remember that you are the only person who can decide whether taking part is right for you. To help you through this process, a research nurse working with your doctor will review the project with you, explain any potential risks, and answer your questions. You can also
          <a href='/contact' className='text-blue hover:underline mx-1'>
            reach out to us
          </a>
          here if you have any other questions.
        </p>
      </div>
    </div>
  </div>
);

export default WhereToTakePart;
