import React from 'react';
import arrow from '../../images/arrow.svg';

const Eligibility = () => (
  <div className='py-16 xl:w-2/6 lg:w-3/6 text-sm'>
    <div className='bg-grey-100 p-6 lg:p-8 rounded'>
      <h2 className='font-sans font-semibold text-blue text-lg leading-very-tight mb-4'>
        Who can take part?
      </h2>

      <p className='font-sans font-normal text-grey leading-snug mb-4'>
        If you meet the following criteria, you’re eligible to participate in the NL Genome Project
      </p>

      <ul className='font-sans font-normal text-black mb-4 leading-snug'>
        <li className='flex items-start mb-1'>
          <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
          <span className='flex-1'>
            18 years of age or older
          </span>
        </li>
        <li className='flex items-start mb-1'>
          <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
          <span className='flex-1'>
            Have a valid MCP #
          </span>
        </li>
        <li className='flex items-start mb-1'>
          <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
          <span className='flex-1'>
            Able to provide written or electronic informed consent
          </span>
        </li>
        <li className='flex items-start mb-1'>
          <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
          <span className='flex-1'>
            Willing to be re-contacted
          </span>
        </li>
        <li className='flex items-start mb-1'>
          <img src={arrow} alt='-' className='flex-none h-4 mt-1/2 mr-2' />
          <span className='flex-1'>
            Willing to have your coded data shared with researchers both inside and outside of Canada
          </span>
        </li>
      </ul>
    </div>
  </div>
);

export default Eligibility;
