import React from 'react';
import PropTypes from 'prop-types';
// import { StaticQuery, graphql } from 'gatsby';

const HowItWorksStep = ({ icon, title, body }) => (
  <>
    <img src={icon} alt={title} className='inline-block mb-4 h-16' />

    <h3 className='font-soft font-semibold text-xl leading-very-tight mb-4 lg:h-12'>
      {title}
    </h3>

    <p className='font-sans font-normal text-sm mb-6 lg:mb-0'>
      {body}
    </p>
  </>
);

HowItWorksStep.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
};

HowItWorksStep.defaultProps = {
  icon: '',
  title: '',
  body: '',
};

export default HowItWorksStep;
