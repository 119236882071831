import React from 'react';
import PropTypes from 'prop-types';
// import { StaticQuery, graphql } from 'gatsby';


const Clinic = ({ doctor, clinic, street, city, postal, phone }) => (
  <div className='xl:w-2/5 mt-6 mr-5 p-4 border-solid border-gray-400 border'>
    <div className='text-xl pb-2 font-semibold'>
      { doctor }
    </div>

    <div className='text-sm font-semibold text-grey'>
      { clinic }
    </div>

    <div className='text-sm text-grey'>
      { street }
      <br />
      { city }
      <br />
      { postal }
    </div>

    <div className='text-sm text-grey'>
      { phone }
    </div>
  </div>
);

Clinic.propTypes = {
  doctor: PropTypes.string,
  clinic: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  postal: PropTypes.string,
  phone: PropTypes.string,
};

Clinic.defaultProps = {
  doctor: '',
  clinic: '',
  street: '',
  city: '',
  postal: '',
  phone: '',
};

export default Clinic;
